<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <div class="d-block pt-3 mb-4">
                    <div class="row align-items-center">
                        <div class="col">
                            <h1 class="header-title">
                                Log activity
                                <br><br>
                                <small class="form-text text-muted">
                                    Daftar Log activity
                                </small>
                            </h1>
                        </div>
                        <div class="col-auto">
                            <a href="pengumuman-tambah.html" class="btn btn-sm btn-primary ml-2">
                                <div class="d-flex align-items-center">Export Ke Excel
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- CONTENT -->
                <div class="tab-content">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header pb-3">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari unit...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-sm table-nowrap card-table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>level</th>
                                            <th class="w-100">User</th>
                                            <th>Ip address</th>
                                            <th>Method</th>
                                            <th>Device</th>
                                            <th>Status</th>
                                            <th>Data</th>
                                            <th>Time</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="5">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="list" v-if="!fetching">
                                        <tr v-for="(log, index) in logActivity" :key="index">
                                            <td>
                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>
                                                {{ log.level }}
                                            </td>
                                            <td>
                                                {{ log.fullname }}
                                            </td>
                                            <td>
                                                {{ log.ip_address }}
                                            </td>
                                            <td>
                                                {{ log.method }}
                                            </td>
                                            <td>
                                                {{ log.device }}
                                            </td>
                                            <td>
                                                {{ log.status }}
                                            </td>
                                            <td>
                                                {{ log.data }}
                                            </td>
                                            <td>
                                                {{ log.time }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                Note: <br>
                                <i class="fe fe-more-vertical"></i> Data yang ditampilkan max 7 hari ke belakang
                                terhitung per hari akses <br>
                                <i class="fe fe-more-vertical"></i> Apabila membutuhkan data lebih dari 7 hari
                                ke belakang maka dapat klik export dan
                                pilih tanggal yang ingin dicari
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>


<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Log activity'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                logActivity: [],
                fetching: true,
                pagination: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: ''
            }
        },
        created() {
            this.getData();
        },
        components: {
            vPagination
        },
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/log/activity', {
                        params: {
                            page: this.pagination.currentPage,
                            keywords: this.keywords,
                        }
                    })
                    .then((response) => {
                        this.logActivity = response.data.data;
                        this.pagination.totalPages = response.data.total_page;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            }
        },
    };
</script>